{
  "variables": [
    {
      "name": "userId",
      "type": "number",
      "format": null
    },
    {
      "name": "receiver_username",
      "type": "string",
      "format": null
    },
    {
      "name": "receiver_firstname",
      "type": "string",
      "format": null
    },
    {
      "name": "mobile_app_base_url",
      "type": "string",
      "format": null
    },
    {
      "name": "current_date",
      "type": "Date",
      "format": "lll"
    },
    {
      "name": "current_time",
      "type": "Date",
      "format": "lt"
    },
    {
      "name": "study_email",
      "type": "string",
      "format": null
    },
    {
      "name": "study_phone",
      "type": "string",
      "format": null
    },
    {
      "name": "app_name",
      "type": "string",
      "format": null
    },
    {
      "name": "admin_url",
      "type": "string",
      "format": null
    }
  ],
  "appointment": {
    "variables": [
      {
        "name": "adminId",
        "type": "number",
        "format": null
      },
      {
        "name": "appointmentId",
        "type": "string",
        "format": null
      },
      {
        "name": "startDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "actionByUsername",
        "type": "string",
        "format": null
      },
      {
        "name": "actionForUsername",
        "type": "string",
        "format": null
      }
    ],
    "appointment_due": {
      "variables": []
    }
  },
  "availability": {
    "variables": [
      {
        "name": "startDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "endDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "actionByUsername",
        "type": "string",
        "format": null
      },
      {
        "name": "actionForUsername",
        "type": "string",
        "format": null
      }
    ]
  },
  "login": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      }
    ]
  },
  "activity": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      },
      {
        "name": "activityId",
        "type": "number",
        "format": null
      }
    ]
  },
  "forum": {
    "variables": [
      {
        "name": "forumPostId",
        "type": "number",
        "format": null
      },
      {
        "name": "postTitle",
        "type": "string",
        "format": null
      },
      {
        "name": "postContent",
        "type": "string",
        "format": null
      }
    ]
  },
  "qna": {
    "variables": [
      {
        "name": "qnaPostId",
        "type": "number",
        "format": null
      },
      {
        "name": "commentId",
        "type": "number",
        "format": null
      },
      {
        "name": "questionText",
        "type": "string",
        "format": null
      }
    ]
  },
  "resource": {
    "variables": [
      {
        "name": "notificationId",
        "type": "number",
        "format": null
      },
      {
        "name": "articleId",
        "type": "number",
        "format": null
      },
      {
        "name": "articleTitle",
        "type": "string",
        "format": null
      }
    ]
  },
  "message": {
    "variables": [
      {
        "name": "threadId",
        "type": "number",
        "format": null
      }
    ],
    "thread_assigned_to_me": {
      "variables": [
        {
          "name": "adminMessageThreadUrl",
          "type": "string",
          "format": null
        }
      ]
    }
  },
  "goal": {
    "variables": [
      {
        "name": "goalId",
        "type": "number",
        "format": null
      },
      {
        "name": "goalTitle",
        "type": "string",
        "format": null
      }
    ]
  },
  "badge": {
    "variables": [
      {
        "name": "earnedDate",
        "type": "Date",
        "format": "lll"
      },
      {
        "name": "badgeTitle",
        "type": "string",
        "format": null
      }
    ]
  },
  "incentive": {
    "variables": [
      {
        "name": "earnAmount",
        "type": "number",
        "format": null
      }
    ]
  },
  "survey": {
    "variables": [
      {
        "name": "surveyName",
        "type": "string",
        "format": null
      },
      {
        "name": "daysSinceAvailable",
        "type": "number",
        "format": null
      }
    ],
    "prep_questionnaire_follow_up": {
      "variables": [
        {
          "name": "surveyLink",
          "type": "string",
          "format": null
        }
      ]
    },
    "complete_survey_reminder": {
      "variables": [
        {
          "name": "surveyLink",
          "type": "string",
          "format": null
        }
      ]
    }
  },
  "testkit": {
    "variables": [
      {
        "name": "testKitRequestId",
        "type": "number",
        "format": null
      },
      {
        "name": "daysSinceAvailable",
        "type": "number",
        "format": null
      },
      {
        "name": "testKitName",
        "type": "string",
        "format": null
      }
    ],
    "testkit_pickup": {
      "variables": [
        {
          "name": "participantId",
          "type": "number",
          "format": null
        }
      ]
    }
  },
  "store": {
    "variables": [
      {
        "name": "orderId",
        "type": "number"
      },
      {
        "name": "itemId",
        "type": "number"
      },
      {
        "name": "itemLabel",
        "type": "string"
      }
    ]
  },
  "health_tracker": {
    "variables": [
      {
        "name": "reminderMessage",
        "type": "string"
      }
    ]
  }
}
